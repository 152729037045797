@font-face {
    font-family: 'Optima LT Std';
    src: url('#{$font-path}/OptimaLTStd-Bold.eot');
    src: url('#{$font-path}/OptimaLTStd-Bold.eot?#iefix') format('embedded-opentype'),
        url('#{$font-path}/OptimaLTStd-Bold.woff') format('woff'),
        url('#{$font-path}/OptimaLTStd-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Optima Regular';
    src: url('#{$font-path}/OptimaLTStd-Medium.eot');
    src: url('#{$font-path}/OptimaLTStd-Medium.eot?#iefix') format('embedded-opentype'),
        url('#{$font-path}/OptimaLTStd-Medium.woff') format('woff'),
        url('#{$font-path}/OptimaLTStd-Medium.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
	font-family: 'Avenir';
	src: url('#{$font-path}/Avenir-Heavy.eot');
	src: url('#{$font-path}/Avenir-Heavy.eot?#iefix') format('embedded-opentype'),
		 url('#{$font-path}/Avenir-Heavy.woff') format('woff'),
		 url('#{$font-path}/Avenir-Heavy.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Avenir Book';
	src: url('#{$font-path}/Avenir-Book.eot');
	src: url('#{$font-path}/Avenir-Book.eot?#iefix') format('embedded-opentype'),
		 url('#{$font-path}/Avenir-Book.woff') format('woff'),
		 url('#{$font-path}/Avenir-Book.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Avenir';
	src: url('#{$font-path}/Avenir-Roman.eot');
    src: url('#{$font-path}/Avenir-Roman.eot?#iefix') format('embedded-opentype'),
		 url('#{$font-path}/Avenir-Roman.woff') format('woff'),
		 url('#{$font-path}/Avenir-Roman.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
    font-family: 'Avenir Next';
    src: url('#{$font-path}/AvenirNext-MediumItalic.eot');
    src: url('#{$font-path}/AvenirNext-MediumItalic.eot?#iefix') format('embedded-opentype'),
         url('#{$font-path}/AvenirNext-MediumItalic.woff') format('woff'),
         url('#{$font-path}/AvenirNext-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Avenir Next';
    src: url('#{$font-path}/AvenirNext-BoldItalic.eot');
    src: url('#{$font-path}/AvenirNext-BoldItalic.eot?#iefix') format('embedded-opentype'),
         url('#{$font-path}/AvenirNext-BoldItalic.woff') format('woff'),
         url('#{$font-path}/AvenirNext-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Avenir Next';
    src: url('#{$font-path}/AvenirNext-Medium.eot');
    src: url('#{$font-path}/AvenirNext-Medium.eot?#iefix') format('embedded-opentype'),
         url('#{$font-path}/AvenirNext-Medium.woff') format('woff'),
         url('#{$font-path}/AvenirNext-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
	font-family: 'Segoe Script';
	src: url('#{$font-path}/segoe-script.eot');
    src: url('#{$font-path}/segoe-script.eot?#iefix') format('embedded-opentype'),
		 url('#{$font-path}/segoe-script.woff') format('woff'),
		 url('#{$font-path}/segoe-script.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
    font-family: 'Project-AR';
    src:    url('#{$font-path}/Project-AR.eot?vogz5j');
    src:    url('#{$font-path}/Project-AR.eot?vogz5j#iefix') format('embedded-opentype'),
        url('#{$font-path}/Project-AR.ttf?vogz5j') format('truetype'),
        url('#{$font-path}/Project-AR.woff?vogz5j') format('woff'),
        url('#{$font-path}/Project-AR.svg?vogz5j#Project-AR') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
	font-family: 'Javacom';
	src: url('#{$font-path}/Javacom.eot');
	src: url('#{$font-path}/Javacom.eot?#iefix') format('embedded-opentype'),
		url('#{$font-path}/Javacom.woff2') format('woff2'),
		url('#{$font-path}/Javacom.woff') format('woff'),
		url('#{$font-path}/Javacom.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'Project-AR' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-arrow:before {
    content: "\e900";
}
.icon-pencil:before {
    content: "\e901";
}
.icon-cog:before {
    content: "\e902";
}
.icon-facebook-square:before {
    content: "\f082";
}
.icon-pinterest-square:before {
    content: "\f0d3";
}
.icon-instagram:before {
    content: "\f16d";
}
.icon-linkedin-square:before {
    content: "\f08c";
}

h1 {
    font-size: 4.46666666667em;
    text-transform: uppercase;
    font-weight: 400;
    line-height: 1.17em;
}

h2 {
    font-size: 3.2em;
    font-weight: 300;
}

h3 {
    font-size: 1em;
}

h4 {
    font-size: 0.9em;
    font-weight: 200;
}

h5 {
    font-size: 0.8em;
    font-weight: 200;
}

h6 {
    font-size: 0.6em;
    font-weight: 200;
}

p {
    text-align: left;
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 10px;
}

blockquote {
    float: left;
    padding: 10px 20px;
    margin: 0 0 20px;
    font-size: 17.5px;
    border-left: 15px solid #eee;
}

blockquote p {
    font-family: Georgia, "Times New Roman", Times, serif;
    font-style: italic;
    color: #494949;
}

.btn {
    padding: 15px 30px 15px 0;
    line-height: 17px;
    letter-spacing: 2px;
    font-weight: 800;
    font-size: 16px;
    color: #000;
    font-family: $primary-font;
    transition: $main-transition;
    text-decoration: none;

    i {
        display: inline-block;
        transition: $main-transition;
    }

    &:hover {
        color: #666;

        i {
            color: #000;
        }
    }
}
