.nav-button-holder {
    display: block;
    float: right;
    margin-left: 10px;
    padding-left: 22px;

    @include breakpoint($min-big-desktop) {
        display: none;
    }
}

.nav-button {
    position: relative;
    float: right;
    width: 30px;
    height: 24px;
    margin: 10px 0 0;
    cursor: pointer;
    opacity: 1;

    span {
        position: relative;
        float: left;
        width: 100%;
        height: 4px;
        margin-bottom: 4px;
        background: $primary-color;
    }
}

.nav-holder {
    display: none;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    padding: 30px 0;
    overflow: auto;
    background: $secondary-color;

    @include breakpoint($min-big-desktop) {
        display: block !important;
        position: relative;
        float: right;
        display: block;
        top: auto;
        width: auto;
        padding: 0;
        opacity: 1;
        overflow: initial;
    }
}

nav {
    position: relative;
    float: left;
    width: 100%;

    li {
        position: relative;
        float: left;
        width: 100%;
        left: 0;
        right: 0;
        margin-bottom: 10px;
        text-align: left;
        opacity: 1;

        @include breakpoint($min-big-desktop) {
            width: auto;
            left: auto;
            right: auto;
            margin: 0;
            text-align: initial;
        }

        a {
            position: relative;
            float: left;
            top: 0;
            padding: 15px 22px;
            font-family: $secondary-font;
            font-size: 14px;
            line-height: 1;
            letter-spacing: .25px;
            text-transform: uppercase;
            color: $primary-color;
            transition: $main-transition;

            &.act-link {
                font-family: $primary-font;
                font-weight: 900;
                color: $accent-color;
            }

            &:hover {
                color: $accent-color;
            }
        }
    }
}

.selectMe {
    display: none;
    position: absolute;
    top: 50%;
    width: 70px;
    height: 50px;
    right: -30px;
    margin-top: -25px;
    border-left: 6px solid #eee;
    line-height: 50px;
    cursor: pointer;
    transition: 0.5s linear;
    color: $primary-color;
    z-index: 11;

    &:hover {
        color: $accent-color;
    }

    @include breakpoint($min-desktop) {
        display: block;
    }

    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: -6px;
        width: 6px;
        height: 0;
        background: $accent-color;
        transition: 0.3s linear;
    }

    &:hover:before {
        height: 100%;
    }
}
