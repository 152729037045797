.about #wrapper {
    background: #f4f4f4 $main-pattern;
}

.is-about .section-title,
.is-about p {
    color: $accent-color;
}

.is-about .with-border-bottom {
    border-color: $accent-color;
}

.about-images {
    @include clearfix();
}

.about-image {
    display: block;
    float: left;
    width: 100%;
    padding-top: 100%;
    margin-bottom: 15px;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;

    @include breakpoint($min-tablet) {
        @include span (4 of 12);
        margin-bottom: gutter();
        padding-top: 33%;

        &:nth-child(3n) {
            margin-right: 0;
        }
    }

}

.old-images .about-image{
    filter: grayscale(100%);
    transition: $main-transition;

    &:hover {
        filter: grayscale(0%);
    }
}

.about-big-image {
    width: 100%;
    padding-top: 100%;
    background-position: 100% 50%;
    background-repeat: no-repeat;
    background-size: cover;

    @include breakpoint($min-tablet) {
        padding-top: 50%;
    }
}
