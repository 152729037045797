.cookie-law-info-bar {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 20px;
    text-align: center;
    color: #fff;
    transition: bottom 0.3s linear 0s;
    background-color: rgba(12, 12, 12, 0.8);
    z-index: 9999999;

    p {
        color: #fff;
        display: block;
        margin-bottom: 20px;
        line-height: 1.3;

        @include breakpoint($min-desktop) {
            display: inline-block;
        }
    }
}

.cookie-law-info-bar.out {
    bottom: -250px;
}

.cookie-law-info-bar a{
    text-decoration: underline;
    color: lighten($accent-color, 10%);
    transition: 0.2s ease;

    &:hover {
        color: $accent-color;
    }
}

.cookie-law-info-bar .button {
    height: auto;
    width: auto;
    display: inline-block;
    margin: 0 20px;
    color: #fff;
    padding: 10px 20px;
    font: bold 20px/1.3 $primary-font;
    text-transform: uppercase;
    text-decoration: none;
    border: 1px solid currentColor;
    transition: 0.2s ease;

    &:hover {
        color: lighten($accent-color, 10%);
        border-color: currentColor;
    }
}
