.slide-title-holder {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 3;

    @include breakpoint($min-big-desktop) {
        width: 90%;
    }
}

.slide-title {
    position: relative;
    top: 80%;
    margin: 0 auto;
    padding: 50px;

    @include breakpoint($min-big-desktop) {
        float: right;
        // border-right: 10px solid #fff;
        padding: 50px 50px 50px 0;
    }

    h3,
    h4 {
        position: relative;
        width: 100%;
        text-align: right;
        text-transform: uppercase;
        letter-spacing: 2px;
        color: #fff;

    }
    h3 {
        top: 0;
        margin-bottom: 10px;
        font: 22px/1 $decorative-font-2;
        opacity: 1;
        text-transform: none;

        @include breakpoint($min-big-desktop) {
            font: 40px/1 $decorative-font-2;
        }
    }

    h4 {
        margin: 0 0 10px;
        float: right;
    }

    a {
        padding-right: 0px;
        float: right;
        font: 14px/1 $secondary-font;
        color: #fff;

        @include breakpoint($min-big-desktop) {
            font-size: 20px;
        }

        &:hover,
        &:hover i {
            color: #fff;
            transform: translateX(6px);
        }
    }

    .icon-arrow:before {
        font-size: 14px;
    }
}
