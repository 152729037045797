.category-more {
    display: block;
    position: relative;
    width: 100%;
    height: 400px;
    overflow: hidden;
    cursor: pointer;

    p {
        position: absolute;
        bottom: 0;
        right: 0;
        margin: 0;
        padding: 19px 34px;
        width: 100%;
        text-align: right;
        text-transform: uppercase;
        font-family: $secondary-font;
        font-size: 20px;
        color: $accent-color;
        background: rgba($secondary-color, 0.9);

        &:after {
            content: '\25B6';
            margin-left: 12px;
        }

        @include breakpoint($min-big-desktop) {
            background: none;
        }
    }
}

.category-more-image {
    display: block;
    width: 100%;
    height: 100%;
    background-size: cover;

    @include breakpoint($min-big-desktop) {
        transition: all .5s ease-out;

        &:hover {
            transform: scale(1.02);
        }
    }
}
