.projects-grid {
    display: block;
    text-align: left;
}

.project {
    position: relative;
    width: 100%;
    padding-top: 100%;
    margin-bottom: 10px;
    overflow: hidden;

    @include breakpoint($min-desktop) {
        width: auto;
        padding-top: calc(50% - 20px);
        margin: 5px;
        display: inline-block;
        width: 48%;

        &:nth-child(odd) {
            margin-left: 0;
        }

        &:nth-child(even) {
            margin-right: 0;
        }
    }

    @include breakpoint($min-big-desktop) {
        width: auto;
        padding-top: calc(33% - 20px);
        width: 32.3%;

        &:nth-child(odd), &:nth-child(even) {
            margin: 5px;
        }

        &:nth-child(3n+3) {
            margin-right: 0;
        }

        &:first-child, &:nth-child(3n+4) {
            margin-left: 0;
        }

        a:not(.inline_edit_link):before {
            content: '';
            width: 100%;
            height: 15%;
            position: absolute;
            bottom: 0;
            left: 0;
            background: rgba($secondary-color, 0.8);
            transition: height .5s ease-out;
        }

        a:not(.inline_edit_link):hover:before {
            height: 100%;
        }
    }

    &.project-bg {
        background-size: cover;
    }

    &:hover .project-image-square {
        transform: scale(1.02);
    }

    &:hover .project-name {
        background: rgba($secondary-color, 0.5);
    }

    a {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-size: cover;

        &.no-image {
            background: $secondary-color;
        }
    }
}

.project-vertical {
    display: inline-block;
    width: 48%;
    padding-top: 0;
    height: 50vh;
    max-height: 490px;

    @include breakpoint($min-tablet) {
        width: 23%;
    }

    &:nth-child(odd), &:nth-child(even),
    &:nth-child(3n+3),
    &:first-child, &:nth-child(3n+4) {
        margin: 0.7%;
    }

    a:not(.inline_edit_link):before {
        content: none;
    }

    a:hover {
        .project-vertical-info {
            opacity: 1;
            transition: opacity 0.5s ease;
        }

        svg {
            fill: $secondary-color-dark;
            transition: transform .5s ease-in;
        }
    }
}

.project-name {
    width: 100%;
    color: $accent-color;
    letter-spacing: 3.75px;
    font: bold 30px $secondary-font;
    text-transform: uppercase;
    padding: 40px 20px;
    position: absolute;
    bottom: 0;
    background: rgba($secondary-color, 0.8);
    text-align: center;
    transition: background .5s ease-in .2s;

    &:after {
        font-family: 'Project-AR' !important;
        content: '\e900';
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        text-transform: none;
        line-height: 1;
        font-size: 12px;
        padding-bottom: 16px;
    }

    @include breakpoint($min-desktop) {
        padding: 40px;
    }

    @include breakpoint($min-big-desktop) {
        &:after {
            color: $accent-color;
        }
    }
}

.project-image-square,
.project-image-vertical {
    position: absolute;
    top: 0;
    left: 0;
    transition: transform .5s ease-in;
}

.project-image-vertical {
    width: 100%;
}

.project-vertical-info {
    width: 100%;
    height: 60%;
    opacity: 1;
    position: absolute;
    bottom: 0;
    overflow: auto;
    background: rgba($primary-color, 0.7);
    padding: 20px 10px;

    @include breakpoint($min-desktop-large) {
        padding: 60px 20px;
        height: 100%;
        opacity: 0;
        background: $primary-color;
    }

    p,
    span {
        color: $secondary-color-dark;
        font-family: $secondary-font;
        font-size: 12px;

        @include breakpoint($min-desktop-large) {
            font-size: 17px;
        }
    }

}

.project-vertical-title {
    display: flex;
    justify-content: space-between;
    font-family: $secondary-font;

    span {
        padding-top: 4px;
        font-family: $tertiary-font;
        color: $highlighted-color;
    }
}

.project-vertical-subtitle {
    padding-bottom: 20px;
    font-style: italic;
    font-family: $secondary-font;
}

.project-vertical-text {
    p,
    ul,
    li {
        font-family: $tertiary-font;
        font-weight: normal;
        font-size: 10px;
        line-height: 1.2;

        @include breakpoint($min-desktop-large) {
            font-size: 15px;
        }
    }
}

.project-vertical-features {
    display: none;

    @include breakpoint($min-desktop) {
        display: block;
        color: $secondary-color-dark;
    }
}

.project-vertical-arrow {
    position: absolute;
    bottom: 5px;
    right: -30px;
    fill: $secondary-color-dark;

    @include breakpoint($min-desktop-large) {
        bottom: 40px;
        right: 0;
        fill: $primary-color;
    }

    svg {
        height: 2em;

        @include breakpoint($min-desktop-large) {
            height: 3em;
        }
    }
}
