.error-section {
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.error {
    position: relative;

    img {
        max-width: 100%;
        position: absolute;
        top: -60%;
        z-index: -1;
        left: 50%;
        transform: translateX(-50%);

        @include breakpoint($min-desktop) {
            bottom: -50px;
        }
    }

    h1 {
        font-family: $secondary-font;
        font-size: 64px;
        color: $secondary-color;
        letter-spacing: 8px;
        line-height: 38px;
        margin-bottom: 26px;
    }

    p {
        font-family: $primary-font;
        font-size: 24px;
        color: $primary-color;
        letter-spacing: 0.42px;
        line-height: 29px;
        text-align: center;
    }

    a {
        color: $primary-color;
    }
}
