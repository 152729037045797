footer.main-footer {
    position: relative;
    width: 100%;
    padding: 45px 0 38px;
    background: $secondary-color;
    z-index: 10;
}

.footer-logo,
.footer-social,
.footer-col {
    float: left;
    width: 45%;
    margin-bottom: 20px;

    @include breakpoint($min-tablet) {
        width: 30%;
    }

    @include breakpoint($min-big-desktop) {
        width: 20%;
    }
}

.footer-logo {
    margin-right: 10%;

    @include breakpoint($min-tablet) {
        width: 65%;
        margin-right: 5%;
    }

    @include breakpoint($min-big-desktop) {
        width: 18%;
        margin-right: 4%;
    }

    a {
        display: block;
    }

    img {
        display: block;
        width: 100%;
        max-width: 160px;
        margin: 0;
    }
}

.footer-social {
    @include breakpoint($min-big-desktop) {
        float: right;
        width: 18%;
        margin-left: 4%;
    }

    ul {
        text-align: left;
    }

    li {
        display: inline-block;
    }

    a {
        display: block;
        padding: 8px 3px;
        font-size: 20px;
        color: $primary-color;
        transition: $main-transition;

        &:hover {
            color: $accent-color;
        }
    }
}

.footer-cols {
    width: 100%;
    float: right;
    font-family: $tertiary-font;

    @include breakpoint($min-big-desktop) {
        width: 56%;
        float: left;
    }
}

.footer-col {
    text-transform: uppercase;

    @include breakpoint($min-tablet) {
        margin-bottom: 0;
    }

    @include breakpoint($min-big-desktop) {
        width: 45%;
        padding-top: 10px;
    }

    &:first-child {
        margin-right: 10%;

        @include breakpoint($min-tablet) {
            margin-right: 5%;
            width: 50%;
        }
    }

    &:last-child {
        clear:both;

        @include breakpoint($min-tablet) {
            clear: none;
            float: right;
            width: 30%;
            margin-bottom: 20px;
        }
    }

    .title {
        font-weight: 900;
        font-family: $secondary-font;
        font-size: 14px;
        letter-spacing: .25px;
    }

    p,
    a {
        display: block;
        color: $primary-color;
        padding-bottom: 0;
        font-size: 12px;
        line-height: 1.5;
        text-align: left;
    }

    a {
        transition: $main-transition;

        &:hover {
            color: #fff;
        }
    }
}

.grid .main-footer {
    @include breakpoint($min-desktop) {
        position: absolute;
        bottom: 0;
    }
}
