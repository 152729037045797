.back-to-top {
    width: 38px;
    height: 38px;
    background: $tertiary-color;
    border: none;
    font-size: 27px;
    text-align: center;
    color: $secondary-color;
    position: absolute;
    right: 10%;
    cursor: pointer;
}
