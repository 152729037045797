input[type=text],
input[type=email],
input[type=search],
input[type=password],
textarea,
select {
    position: relative;
    width: 100%;
    padding-left: 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    font: 14px/50px $primary-font;
    color: #fff;
    border: 0px;
    background: none;
    border-bottom: 1px solid $tertiary-color;
    outline: none;
}

textarea {
    resize: none;
    height: 8em;
    padding: 15px 0 15px 10px;
    line-height: 1.25;
}

::-webkit-input-placeholder { color: $tertiary-color; }
::-moz-placeholder { color: $tertiary-color; } /* firefox 19+ */
:-ms-input-placeholder { color: $tertiary-color; } /* ie */
input:-moz-placeholder { color: $tertiary-color; }


.form {
    margin-bottom: 40px;

    h1 {
        padding: 10px 0 20px;
        font: 700 20px/1 $secondary-font;
        color: $accent-color;
    }
}

.register-form-widget .form-input {
    float: left;
    width: 100%;

    @include breakpoint($min-desktop) {
        &.form-input-name,
        &.form-input-surname,
        &.form-input-phone,
        &.form-input-email {
            width: 48%;
        }

        &.form-input-name,
        &.form-input-phone {
            margin-right: 4%;
        }
    }

    li {
        padding: 10px 0;
        color: $error-color;
        font-size: 15px;
    }

}

.ie .form-input {
    height: 53px;
    input {
        height: 100%;
    }
}

.form-fields,
.form-footer {
    @include clearfix();
}

.ie .form-footer {
    margin-top: 90px;
}

.form-privacy {
    display: block;
    width: 100%;
    padding: 10px;
    text-align: left;
    color: $tertiary-color;

    @include breakpoint($min-tablet) {
        width: 50%;
        float: left;
        margin-top: 10px;
    }

    label {
        cursor: pointer;
        font-size: 14px;

        iframe {
            padding-top: 2px;
        }
    }

    a {
        color: $secondary-color;
        transition: $main-transition;

        &:hover {
            color: $primary-color;
        }
    }
}

.form-submit {
    display: block;
    margin-top: 10px;

    @include breakpoint($min-tablet) {
        width: 48%;
        float: right;
        margin-top: 10px;
        text-align: right;
    }

    input {
        display: block;
        width: 100%;
        padding: 10px;
        border-radius: 0;
        font: 800 16px/17px $primary-font;
        text-transform: uppercase;
        vertical-align: middle;
        outline: none;
        cursor: pointer;
        transition: $main-transition;
        background: $tertiary-color;
        color: $secondary-color;
        border: 1px solid $secondary-color;
        position: relative;

        &:after {
            content: '\2192';
            position: absolute;
        }

        &:hover {
            background: $primary-color;
        }

        @include breakpoint($min-tablet) {
            display: inline-block;
            width: 76px;

            &:hover {
                background: transparent;
                letter-spacing: 1px;
            }
        }
    }

    i {
        display: inline-block;
        color: $secondary-color;
        font-size: 16px;
        line-height: 17px;
        vertical-align: middle;
    }
}

.projects-section .call-request-form .form-input-phone,
.show-section .call-request-form .form-input-phone {
    margin-right: 0;
}
