header {
    position: fixed;
    top: 0;
    left: 0;
    padding: 15px 0;
    width: 100%;
    background: $secondary-color;
    z-index: 20;
}

.head-inner {
    position: relative;
    margin: 0 auto;
    width: 92%;
    max-width: 1170px;
    padding: 0;
}

.logo-holder {
    position: relative;
    float: left;
    width: 160px;
    z-index: 21;

    a {
        display: block;
    }

    img {
        display: block;
        max-width: 100%;
    }
}

