@mixin vcenter() {
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}

@mixin text-center() {
	text-align: center;
}

@mixin clearfix() {
    &:before,
    &:after {
        content: "";
        display: table;
    }
    &:after {
        clear: both;
    }
}