.legal-block {
    margin-bottom: 60px;

    h1,
    h2,
    h3,
    h4,
    h5 {
        padding-bottom: 20px;
        font-family: $secondary-font;
        text-transform: uppercase;
        text-align: left;
        color: $accent-color;
    }

    h1 {
        border-bottom: 4px solid $accent-color;
        margin-bottom: 20px;
    }

    p {
        margin-bottom: 20px;
        color: $secondary-color;
    }

    a {
        color: $accent-color;
        text-decoration: underline;
    }

    ul,
    ol {
        margin-bottom: 20px;
        padding-left: 40px;
        list-style: initial;
    }

    li {
        margin-bottom: 5px;
        font-size: 16px;
        text-align: left;
        color: $primary-color;
    }

    b,
    strong {
        font-weight: bold;
    }

    i,
    em {
        font-style: italic;
    }
}
