@include global-box-sizing(border-box);

input, button, select, textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

html {
    height: 100%;
}

body {
    position: relative;
    width: 100%;
    height: 100%;
    font-family: $primary-font;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    overflow-x: hidden;
}

.container {
    @include container;
    max-width: 1170px;
}

.vcenter {
    @include vcenter;
}

.text-center {
    @include text-center;
}

.clearfix {
    @include clearfix;
}

section {
    position: relative;
    width: 100%;
    padding: 30px 0;

    @include breakpoint($min-big-desktop) {
        padding: 50px 0;
    }

    &.is-portfolio,
    &.is-detail,
    &.is-old,
    &.is-contact,
    &.is-legal {
        padding: 0;
        border: 0;
    }
}

#main {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 2;
}

#wrapper {
    position: relative;
    min-height: 100%;
    z-index: 2;
    background: $accent-color;
}

.content {
    @include clearfix();

    &.is-portfolio {
        @include breakpoint($min-big-desktop) {
            float: left;
            width: 70%;
            margin-left: 30%;
            background: $main-pattern;
        }
    }

}
.container {
    position: relative;
    width: 92%;
    max-width: 1024px;
    margin: 0 auto;
    z-index:2;
}

.wrapper-inner {
    position: relative;
    width: 100%;
    padding: 90px 0;
    background: #fff;
    z-index: 3;

    &.is-portfolio {
        float: right;
        padding: 0;
        background: transparent;
    }

    &.is-detail {
        background: transparent;
        padding: 90px 0 20px;
    }

    &.is-about,
    &.is-old,
    &.is-legal {
        padding: 50px 0 0;
        background: transparent;
    }

    &.is-contact {
        background: transparent;
    }

    @include breakpoint($min-big-desktop) {
        padding: 150px 0;
    }
}

.bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size:  cover;
    background-attachment:  scroll;
    background-position:  center;
    background-repeat: repeat;
    background-origin:  content-box;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.2;
}

.transition {
    transition: 0.5s linear;
}
