.main-banner {
    position: relative;
    margin-top: 70px;
    width: 100%;
    height: 400px;
    background-size: cover;
    background-position: 50%;

    @include breakpoint($min-desktop) {
        height: 600px;
    }

    &.is-about {
        background-position: 90% 50%;
    }
    
    &.is-legal {
        background: url(#{$frontend-url}/stone-mobile.jpg) no-repeat center top;
        background-size: cover;

        @include breakpoint($min-desktop) {
            background: url(#{$frontend-url}/stone-desktop.jpg) no-repeat center 20%;
        }
    }
}

.main-banner-content {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    max-width: 340px;
    padding: 0 20px;
    text-align: center;
    transform: translate(-50%,-50%);

    @include breakpoint($min-desktop) {
        max-width: 400px;
    }
}

.main-banner-title {
    font-size: 24px;
    font-family: $secondary-font;
    text-transform: uppercase;

    @include breakpoint($min-desktop) {
        font-size: 32px;
    }
}

.main-banner-text {
    margin: 10px 0 0;
    font-size: 18px;
    font-family: $primary-font;
    text-align: center;

    @include breakpoint($min-desktop) {
        font-size: 24px;
    }
}
