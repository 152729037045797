.project-image {
    position: relative;
    width: 100%;
    height: 200px;
    margin-top: 40px;
    margin-bottom: 20px;

    @include breakpoint($min-tablet) {
        height: 300px;
    }

    @include breakpoint($min-big-desktop) {
        height: 500px;
    }

    .gallery-cell {
        position: relative;
        width: 100%;
        height: 100%;
        background-repeat: none;
        background-position: center center;
        background-size: cover;
    }

    .no-image {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        color: #fff;
    }

    .next,
    .previous {
        background: transparent;

        svg {
            width: 40%;
            height: 40%;
            top: 30%;
            left: 30%;
        }

        .arrow {
            transition: $accent-color;
            fill: $primary-color;
        }

        &:hover {
            .arrow {
                fill: $accent-color;
            }

            &:before {
                width: 100%;
            }
        }

        @include breakpoint($min-tablet) {
            display: block;
        }
    }

    .flickity-page-dots {
        bottom: -40px;

        .dot {
            background: transparent;
            width: 12px;
            height: 12px;
            border: 1px solid $secondary-color;
            margin: 0 5px;
        }

        .dot.is-selected {
            background: $secondary-color;
            width: 14px;
            height: 14px;
        }
    }
}

.bottom-container {
    margin-top: 60px;

    @include breakpoint($min-desktop) {
        display: flex;
    }
}

.details-container {
    text-align: left;
    font: 14px/2 $variant-font-2;
    color: $tertiary-color;

    @include breakpoint($min-desktop) {
        flex: 1 1 40%;
        margin-top: 30px;
    }

    .detail-name {
        text-transform: uppercase;
        margin-right: 2px;
    }
}

.download-detail {
    display: block;
    position: relative;
    color: currentColor;
    margin-top: 21px;
    font: 900 18px/1.6 $primary-font;
    text-decoration: underline;
    transition: color .5s ease-out;

    &:hover {
        color: $accent-color;
    }

    &:after {
        content: '\2193';
        margin-left: 11px;
        font-size: 22px;
        text-decoration: none;
        bottom: 7px;
        position: absolute;
        border-bottom: 1px solid currentColor;
        line-height: 1;
    }
}

.contact-container {
    margin-top: 30px;

    @include breakpoint($min-desktop) {
        flex: 1 1 70%;
    }
}

.form-container:first-of-type {
    padding: 20px 15px;
    border: 1px solid $tertiary-color;

    input, textarea {
        border-color: $tertiary-color;
        color: $tertiary-color;
    }

    @include breakpoint($min-desktop) {
        display: flex;
    }
}

.form-claim {
    letter-spacing: 2.66px;
    font: 16px/1.5 $secondary-font;
    color: $tertiary-color;
    text-transform: uppercase;

    @include breakpoint($min-desktop) {
        border-right: 1px solid currentColor;
        padding: 50px 16px;
        flex: 1 1 20%;
        margin-right: 20px;
        align-self: center;
        text-align: right;
    }
}

.call-request-form {
    margin-top: 30px;
    background: $tertiary-color;
    color: $accent-color;
    padding: 10px;
    position: relative;

    @include breakpoint($min-desktop) {
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    label {
        display: block;
        color: currentColor;
        font: 14px $primary-font;
        text-transform: uppercase;
        width: auto;
        margin-bottom: 5px;

        @include breakpoint($min-desktop) {
            flex: 1 1 96px;
            margin: 0;
            margin-right: 5px;
        }
    }

    input {
        color: $accent-color;
        border: 1px solid currentColor;
        height: 40px;

        @include breakpoint($min-desktop) {
            width: 100%;
        }
    }

    .form-input-phone {
        width: 79%;
        margin-right: 0;

        ul {
            position: absolute;
            width: 100%;
            background: $secondary-color;
            left: 0;
        }


        @include breakpoint($min-desktop) {
            float: none;
            width: auto;
            flex: 1 1 270px;

            li {
                padding-left: 46px;
            }
        }
    }

    ::-webkit-input-placeholder { color: $accent-color; }
    ::-moz-placeholder { color: $accent-color; } /* firefox 19+ */
    :-ms-input-placeholder { color: $accent-color; } /* ie */
    :-moz-placeholder { color: $accent-color; }

    .phone-submit {
        color: $accent-color;
        border: 1px solid currentColor;
        display: inline-block;
        margin-bottom: 0;
        position: relative;
        width: 19%;
        background: transparent;
        cursor: pointer;
        height: 40px;
        text-align: center;
        transition: background .5s ease-out, color .5s ease-out, border-color .5s ease-out;

        &.icon-arrow:before {
            font-size: 12px;
            line-height: 3;
        }
        &:hover {
            background: $accent-color;
            color: $secondary-color;
            border-color: $accent-color;
        }

        @include breakpoint($min-desktop) {
            flex: 0 0 40px;
            margin-left: 5px;
        }
    }

    input[type="submit"] {
        background: transparent;
        border: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
    }
}

.ie {
    .call-request-form {
        input {
            line-height: 1;
        }

        input[type="submit"] {
            bottom: -4px;
        }
    }

    @include breakpoint($min-desktop) {
        .form-container {
            display: inline-block;

            article {
                display: inline-block;
                width: 60%;
            }
        }

        .form-claim {
            display: inline-block;
            float: left;
            width: 30%;
        }

        .call-request-form input[type="submit"] {
            bottom: auto;
        }

        .download-detail:after {
            font-size: 27px;
            border: none;
        }
    }
}
