.is-granted-edit-inline {
    .edit-inline-button {
        @include vcenter;
        position: fixed;
        left: 0;
        z-index: 9999;
        background-color: $secondary-color;
        cursor: pointer;
        height: 40px;
        width: 40px;
        font-size: 24px;
        line-height: 40px;
        text-align: center;
        border-radius: 0 10px 10px 0;

        button {
            color: $secondary-color;
            font-weight: 300;
        }
    }

    .inline_edit_link {
        text-decoration: none;
        border: 1px solid $secondary-color;
        padding: 0 !important;
        line-height: 35px;
        box-shadow: none;
        background-color: $primary-color;
        color: $secondary-color !important;
        width:35px;
        height:35px;
        border-radius:50px;
        font-size: 20px !important;
        text-align: center;

        i:before {
            color: white;
            font-size: 16px;
            line-height: 30px;
            content: "\e60c";
        }

        &:hover {
            color: $primary-color !important;
            background-color: $tertiary-color;
        }
    }

    .button-edit-inline {
        border: none;
        background-color: transparent;

        :focus {
            outline: none;
        }
    }

    .edit-inline-logout i {
        text-decoration: none;
        float: right;
        color: $primary-color;
        font-size: 20px;
        font-weight: bold;
        padding-top: 5px;
        padding-right: 5px;
    }
}

.edit_banner_home_link {
    bottom: 20px;
    top: auto;
    left: 20px;
}

.edit_default_link {
    bottom: 20px;
    top: auto;
    left: auto;
    right: 20px;
    display: none;
}

.header-pencil {
    bottom: 150px;
}

.admin-nav {
    position: fixed;
    left: 0;
    width: 100%;
    height: 30px;
    z-index: 10;
    background-color: $secondary-color;
    font-size: 14px;
    line-height: 30px;
    overflow: hidden;
}

.admin-nav-item {
    float: left;
    cursor: pointer;
    background-color: $primary-color;

    a,
    button {
        cursor: pointer;
        font-family: $primary-font;
        color: $secondary-color;
        margin: 0;
        padding: 0 10px;
    }

    &:hover {
        background-color: $primary-color;

        button {
            background-color: $primary-color;
            color: $secondary-color;
        }
        a, button {
            color: $secondary-color;
        }
    }

    &:not(:last-child) {
        border-right: solid 1px $tertiary-color;
    }
}

.admin-nav-item .admin{
    padding: 0;
}

.admin-nav-item img{
    max-height: 40px;
    position: relative;
    top: -5px;
}

.admin-nav-item a{
    text-decoration: none;
}

.is-granted-edit-inline {
    .header {
        top: 30px;

        .secondary {
            top: 30px;
        }

        .inline_edit_link {

            left: 30px;

            @include breakpoint($min-tablet) {
                left: 10%;
            }

            @include breakpoint($min-desktop) {
                left: 50px;
            }
        }
    }
}

.highlighted {
    background-color: rgba(gray, 0.3) !important;
}

.edit-menu-top {
    top: 15px;
    right: -30px;
}
