article {
    margin-bottom: 40px;
    padding-bottom: 38px;

    &:last-child {
        border: none;
        padding-bottom: 0;
        margin-bottom: 16px;
    }

    p {
        margin-bottom: 10px;
        color: $secondary-color;
        text-align: justify;
    }
}

.with-border-bottom {
    border-bottom: 1px solid rgba($secondary-color, 0.5);
}

.top-section {
  margin-top: 69px;
}

.contact-section {
    @include breakpoint($min-tablet) {
        display: flex;
        padding-top: 55px;
    }

    @include breakpoint($min-desktop) {
        padding-bottom: 70px;
    }
}

.projects-section {
  article {
    padding-bottom: 0;
    margin-bottom: 28px;

    p {
      @include breakpoint($min-big-desktop) {
        width: 100%;
      }
    }
  }

  .contact-container {
      max-width: 700px;
      margin-left: auto;
      margin-right: auto;
  }
}

.show-section {
  article {
    padding-bottom: 0;
    margin-top: 60px;
      p {
        width: 100%;
      }
  }
}

.grid .projects-section {
    @include breakpoint($min-desktop) {
        padding-bottom: 300px;
    }
}
