.section-title {
    width: 80%;
    position: relative;
    padding-bottom: 30px;
    font: 30px/1.2 $secondary-font;
    letter-spacing: 3px;
    text-transform: uppercase;
    text-align: left;
    color: $secondary-color;
    &.contact {
        font: 22px/1.2 $secondary-font;
    }
}
