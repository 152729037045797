$frontend-url: '/img';
$font-path: '/fonts';

$primary-font: 'Avenir';
$secondary-font: 'Optima LT Std';
$tertiary-font: 'Optima Regular';
$variant-font: 'Avenir Book';
$variant-font-2: 'Avenir Next';
$decorative-font: 'Segoe Script';
$decorative-font-2: 'Javacom';

$susy: (
  columns: 12,
  container: 95%
);

$primary-color: #fff;
$accent-color: #D8D1CA;
$secondary-color: #7B6E66;
$secondary-color-dark: #646464;
$tertiary-color: #B3A7A1;
$highlighted-color: #E86856;
$error-color: #E86856;



$main-pattern: url(#{$frontend-url}/pattern.png) repeat left top scroll;

$main-transition: 0.2s linear;

/* Media queries */
$max-mobile: max-width 540px;
$min-tablet: min-width 540px;
$min-desktop: min-width 768px;
$min-desktop-large: min-width 992px;
$max-big-desktop: max-width 1036px;
$min-big-desktop: min-width 1036px;
