.loader {
    position: fixed;
    top: 50% !important;
    left: 50%;
    width: 50px;
    height: 50px;
    z-index: 100;
    font-size: 34px;
    line-height: 50px;
    color: #ccc;
    padding-right: 20px;
    opacity: 0.8;

    .fa-spin {
        vertical-align: middle;
        transform-origin: 50% 50%;
        animation: spin 0.5s infinite linear;
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(359deg);
        }
    }
}
