.contact-section {
    article {
        margin: 0;

        @include breakpoint($min-desktop) {
            flex: 1 1 50%;
            padding: 0;
            display: flex;
            flex-direction: column;
        }
    }

    address {
        position: relative;

        @include breakpoint($min-desktop) {
            margin-bottom: 14px;
        }
    }
}



.contact-extra-column {
    display: none;

    @include breakpoint($min-desktop) {
        display: block;
        max-height: 100%;
        flex: 1 1 30%;
        padding-left: 49px;
    }
}

.contact-img {
    display: none;

    @include breakpoint($min-desktop) {
        display: block;
        height: 923px;
        background: url(#{$frontend-url}/postit-desktop.jpg) no-repeat 50%;
        background-size: cover;
    }
}

.wrapper-inner.is-contact {
    padding-bottom: 40px;
}

section.is-contact {
    @include breakpoint($min-desktop) {
        width: 70%;
    }
}

.contact-details {
    margin-top: 40px;
    @include breakpoint($min-big-desktop) {
        width: 48%;
        float: left;

        &:last-child {
            margin-left: 4%;
        }
    }
}

.contact-content {
    font-family: $primary-font;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.25px;
    text-align: left;
    color: $tertiary-color;

    &.title {
        font-weight: 900;
    }

    a {
        color: $tertiary-color;
        transition: $main-transition;

        &:hover {
            color: $secondary-color;
        }
    }
}

div[class*='map'] {
    display: block;
    margin-top: 10px;
    width: 100%;
    height: 250px;

    @include breakpoint($min-desktop) {
        height: 300px;
    }
}

.form-response {
    float: left;
    width: 100%;
    border-top: 4px solid $accent-color;
    padding: 24px 0;
    color: $primary-color;
    font-size: 20px;

    b {
        font-weight: bold;
    }
}

.is-contact .form {
    width: 100%;
    clear: both;
}
